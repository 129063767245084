export const deliveryStatusesCodes = {
    pnd: "Pendiente",
    cnf: "Confirmada",
    rqu: "Solicitando unidad",
    mip: "Fabricación en progreso",
    trs: "Transito",
    cmp: "Completado",
    rtp: "Listo para recoger",
    cld: "Cancelada",
    srn: "Inicio retorno",
    rtn: "Retornada"
};

export const deliveryStatusesMessageClass = {
    pnd: "text-warning",
    cnf: "text-primary",
    rqu: "text-info",
    mip: "text-info",
    trs: "text-info",
    cmp: "text-success",
    rtp: "text-success",
    cld: "text-danger",
    srn: "text-danger",
    rtn: "text-danger"
};

export const deliveryStatuses = {
    cancelled: "cld",
    completed: "cmp",
    confirmed: "cnf",
    manufactory_in_progress: "mip",
    pending: "pnd",
    ready_to_pickup: "rtp",
    returned: "rtd",
    requested_unit: "rqu",
    started_return: "srt",
    transit: "trs",
    wildcard: "wcd"
};
