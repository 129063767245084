import { DateTime } from "luxon";
import numeral from "numeral";

import { TIMEZONE } from "@/common/constants";
import { citiesCodes } from "@/common/fields/cities-codes";

export const moneyFilter = function (value) {
    if (!value) {
        return "$ 0";
    }

    return numeral(value).format("$ 0[.]000 a");
};

export const cityFilter = function (value) {
    return citiesCodes[value] || "Indefinido";
};

export const currencyFilter = function (
    value,
    abbreviation = true,
    join = false
) {
    if (!value) {
        return "$ 0";
    }
    const space = join ? "" : " ";
    return numeral(value).format(
        abbreviation ? `$${space}0,0` : `$${space}0,0.00'`
    );
};

export const transformStringFilter = function (value = "", mode = "") {
    if (mode === "lower") {
        return value.toLowerCase();
    }
    if (mode === "upper") {
        return value.toUpperCase();
    }
    if (mode === "capitalize") {
        return value
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    }

    return value;
};

const icons = {
    store: "🏪",
    warehouse: "🏬"
};
const types = {
    store: "tienda",
    warehouse: "bodega"
};

const fns = {
    icon: (input, type) => {
        const out = icons[type] || "";
        return `${input} ${out}`;
    },
    type: (input, type) => {
        const out = types[type] || "";
        return `${input} ${out}`;
    }
};

export const merchantInfoFilter = function (value, ...options) {
    let result = "";

    options.forEach((item) => {
        const fn = fns[item];
        if (fn) {
            result = fn(result, value);
        }
    });
    return result.trim();
};

export const percentFilter = function (value) {
    if (!value) {
        return "0 %";
    }
    return numeral(value).format("0.00%");
};

export const dateFilter = function (value, isUTC = false) {
    const format = "yyyy-MM-dd HH:mm";

    if (!value) {
        return "Fecha invalida";
    }

    const date = DateTime.fromJSDate(new Date(value));
    if (isUTC) {
        return date.toUTC().toFormat(format);
    }
    return date.setZone(TIMEZONE).toFormat(format);
};

export const dateSimpleFilter = function (value, isUTC = false) {
    const format = "yyyy-MM-dd";
    if (!value) {
        return "Fecha invalida";
    }

    const date = DateTime.fromJSDate(new Date(value));
    if (isUTC) {
        return date.toUTC().toFormat(format);
    }
    return date.setZone(TIMEZONE).toFormat(format);
};

export const imagesLengthFilter = function (value, len = "normal") {
    return value.replace("large", len);
};

export const cutStringFilter = function (value, limit = 20) {
    if (!value) {
        return "";
    }
    return value.length < limit - 3
        ? value
        : `${value.slice(0, limit - 3)} ...`;
};

export const addressFilter = function (value) {
    return value
        .replace(/(CALLE|CLL|Avenida Calle)/gi, "Cl")
        .replace(/(SUR)/gi, "sur")
        .replace(/(BIS)/gi, "bis")
        .replace(" - ", "-")
        .replace(/(CARRERA|CRA|CR|KRA|KR|Avenida Carrera)/gi, "Kr");
};
