import store from "@/store";
import Vue from "vue";
import VueRouter from "vue-router";

import {
    ENABLED_AUTH,
    MARKETPLACE_PORTAL_TITLE,
    SITE_BASE_PATH
} from "@/common/constants";

import Callback from "../pages/callback.vue";
import Index from "../pages/index.vue";
import Login from "../pages/login.vue";
import NotFound from "../pages/not-found.vue";

Vue.use(VueRouter);

function redirectToBuilder(route = { name: "AppIndex" }) {
    return function (_to, _from, next) {
        return next(route);
    };
}

function noRequiresAuthMiddleware(_to, _from, next) {
    return next();
}

function requiresAuthMiddleware(_to, _from, next) {
    if (store.state.auth.accessToken) {
        return next();
    }

    return next({ name: "Login" });
}

function requiresUnauthenticatedMiddleware(_to, _from, next) {
    if (!store.state.auth.accessToken) {
        return next();
    }
    return next({ name: "AppIndex" });
}

const buildRequiresAuthenticated = (enableAuth) => {
    if (enableAuth) {
        return requiresAuthMiddleware;
    }
    return noRequiresAuthMiddleware;
};

const buildRequiresUnauthenticated = (enableAuth) => {
    if (enableAuth) {
        return requiresUnauthenticatedMiddleware;
    }
    return redirectToBuilder({ name: "AppIndex" });
};

const validateRequiresAuthenticated = buildRequiresAuthenticated(ENABLED_AUTH);

const validateRequiresUnauthenticated =
    buildRequiresUnauthenticated(ENABLED_AUTH);

const routes = [
    {
        path: "/",
        name: "Index",
        component: Index
    },
    {
        path: "/callback",
        name: "Callback",
        component: Callback
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        beforeEnter: validateRequiresUnauthenticated
    },
    {
        path: "/onboarding",
        component: () => import("../layouts/onboarding.vue"),
        children: [
            {
                path: "",
                name: "OnboardingIndex",
                beforeEnter: validateRequiresAuthenticated,
                component: () => import("../pages/onboarding/index.vue"),
                meta: {
                    pageTitle: "Onboarding"
                }
            },
            {
                path: "finish",
                name: "OnboardingFinish",
                beforeEnter: validateRequiresAuthenticated,
                component: () => import("../pages/onboarding/finish.vue"),
                meta: {
                    pageTitle: "Onboarding finalizado"
                }
            },
            {
                path: "store",
                name: "OnboardingNewStore",
                beforeEnter: validateRequiresAuthenticated,
                component: () => import("../pages/app/stores/reduced.vue"),
                meta: {
                    pageTitle: "Crea tu tienda"
                }
            },
            {
                path: "inventories",
                name: "OnboardingInventories",
                beforeEnter: validateRequiresAuthenticated,
                component: () => import("../pages/app/inventories/new.vue"),
                meta: {
                    pageTitle: "Registra inventario"
                }
            },
            {
                path: "order",
                name: "OnboardingNewOrder",
                beforeEnter: validateRequiresAuthenticated,
                component: () => import("../pages/app/orders/new.vue"),
                meta: {
                    pageTitle: "Primera venta"
                }
            }
        ]
    },
    {
        path: "/app",
        component: () => import("../layouts/app.vue"),
        children: [
            {
                path: "",
                name: "AppIndex",
                beforeEnter: validateRequiresAuthenticated,
                component: () => import("../pages/app/index.vue"),
                meta: {
                    pageTitle: "Home"
                }
            },

            {
                path: "banners",
                name: "BannersIndex",
                component: () => import("../pages/app/banners/index.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Listado de banners"
                }
            },
            {
                path: "banners/new",
                name: "BannersNew",
                component: () => import("../pages/app/banners/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Nuevo banner"
                }
            },
            {
                path: "banners/detail/:id",
                name: "BannersDetail",
                component: () => import("../pages/app/banners/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Detalle de banner"
                }
            },

            {
                path: "blogs",
                name: "BlogsIndex",
                component: () => import("../pages/app/blogs/index.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Listado de blogs"
                }
            },
            {
                path: "blogs/new",
                name: "BlogsNew",
                component: () => import("../pages/app/blogs/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Nuevo blog"
                }
            },
            {
                path: "blogs/detail/:slug",
                name: "BlogsDetail",
                component: () => import("../pages/app/blogs/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Detalle de blog"
                }
            },
            {
                path: "blogs/clone/:slugClone",
                name: "BlogsClone",
                component: () => import("../pages/app/blogs/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Clonar blog"
                }
            },

            {
                path: "brands",
                name: "BrandsIndex",
                component: () => import("../pages/app/brands/index.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Listado de marcas"
                }
            },
            {
                path: "brands/highlight",
                name: "BrandsHighlight",
                component: () => import("../pages/app/brands/highlight.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Marcas destacadas"
                }
            },
            {
                path: "brands/new",
                name: "BrandsNew",
                component: () => import("../pages/app/brands/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Registrar marca"
                }
            },
            {
                path: "brands/detail/:slug",
                name: "BrandsDetail",
                component: () => import("../pages/app/brands/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Detalle de marca"
                }
            },
            {
                path: "brands/clone/:slugClone",
                name: "BrandsClone",
                component: () => import("../pages/app/brands/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Clonar marca"
                }
            },

            {
                path: "carts",
                name: "cartsIndex",
                component: () => import("../pages/app/carts/index.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Carros de compras"
                }
            },
            {
                path: "carts/new",
                name: "cartsNew",
                component: () => import("../pages/app/carts/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Registro de carro de compras"
                }
            },
            {
                path: "carts/detail/:id",
                name: "CartDetail",
                component: () => import("../pages/app/carts/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Detalle de carro de compras"
                }
            },
            {
                path: "carts/clone/:idClone",
                name: "CartClone",
                component: () => import("../pages/app/carts/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Clonar carro de compras"
                }
            },

            {
                path: "cash-outputs",
                name: "CashOutputs",
                component: () => import("../pages/app/cash-outputs/index.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Gastos"
                }
            },
            {
                path: "cash-outputs/new",
                name: "CashOutputNew",
                component: () => import("../pages/app/cash-outputs/new.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Registrar gasto"
                }
            },
            {
                path: "cash-outputs/detail/:id",
                name: "CashOutputDetail",
                component: () => import("../pages/app/cash-outputs/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Detalle de gasto"
                }
            },

            {
                path: "categories",
                name: "CategoriesIndex",
                component: () => import("../pages/app/categories/index.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Listado de categorías"
                }
            },
            {
                path: "categories/new",
                name: "CategoriesNew",
                component: () => import("../pages/app/categories/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Nueva categoría"
                }
            },
            {
                path: "categories/highlight",
                name: "CategoriesHighlight",
                component: () =>
                    import("../pages/app/categories/highlight.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Categorías destacadas"
                }
            },
            {
                path: "categories/detail/:slug",
                name: "CategoriesDetail",
                component: () => import("../pages/app/categories/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Detalle de categoría"
                }
            },
            {
                path: "categories/clone/:slugClone",
                name: "CategoriesClone",
                component: () => import("../pages/app/categories/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Clonar categoría"
                }
            },

            {
                path: "customers",
                name: "CustomersIndex",
                component: () => import("../pages/app/customers/index.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Clientes"
                }
            },
            {
                path: "customers/new",
                name: "CustomersNew",
                component: () => import("../pages/app/customers/new.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Registrar cliente"
                }
            },
            {
                path: "customers/detail/:id",
                name: "CustomersDetail",
                component: () => import("../pages/app/customers/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Detalles de cliente"
                }
            },

            {
                path: "employees",
                name: "EmployeesIndex",
                component: () => import("../pages/app/employees/index.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Colaboradores"
                }
            },

            {
                path: "files",
                name: "FilesIndex",
                component: () => import("../pages/app/files/index.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Listado de archivos"
                }
            },
            {
                path: "files/new",
                name: "FilesNew",
                component: () => import("../pages/app/files/new.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Nuevo archivo"
                }
            },
            {
                path: "files/detail/:id",
                name: "FilesDetail",
                component: () => import("../pages/app/files/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Detalle de archivo"
                }
            },

            {
                path: "images",
                name: "ImagesIndex",
                component: () => import("../pages/app/images/index.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Listado de imágenes"
                }
            },
            {
                path: "images/new",
                name: "ImagesNew",
                component: () => import("../pages/app/images/new.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Nueva imagen"
                }
            },
            {
                path: "images/detail/:id",
                name: "ImagesDetail",
                component: () => import("../pages/app/images/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Detalle de imagen"
                }
            },

            {
                path: "inventories",
                name: "InventoriesIndex",
                component: () => import("../pages/app/inventories/index.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Productos en inventario"
                }
            },
            {
                path: "inventories/history",
                name: "InventoriesHistory",
                component: () => import("../pages/app/inventories/history.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Histórico de inventario"
                }
            },
            {
                path: "inventories/input-detail/:id",
                name: "InventoriesInputDetail",
                component: () =>
                    import("../pages/app/inventories/input-detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Detalle de entrada"
                }
            },

            {
                path: "inventories/massive",
                name: "InventoriesMassiveIndex",
                component: () =>
                    import("../pages/app/inventories/massive/index.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Carga masiva de inventario"
                }
            },
            {
                path: "inventories/massive/load",
                name: "InventoriesMassiveLoad",
                component: () =>
                    import("../pages/app/inventories/massive/load.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Carga masiva de inventario"
                }
            },
            {
                path: "inventories/new",
                name: "InventoriesNew",
                component: () => import("../pages/app/inventories/new.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Registro de inventario"
                }
            },
            {
                path: "inventories/detail/:id",
                name: "InventoriesDetail",
                component: () => import("../pages/app/inventories/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Detalle de inventario"
                }
            },
            {
                path: "inventories/:merchantType/:merchantId/detail/:id",
                name: "InventoriesCustomDetail",
                component: () => import("../pages/app/inventories/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Detalle de inventario"
                }
            },

            {
                path: "lines",
                name: "LinesIndex",
                component: () => import("../pages/app/lines/index.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Listado de líneas"
                }
            },
            {
                path: "lines/new",
                name: "LinesNew",
                component: () => import("../pages/app/lines/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Registrar línea"
                }
            },
            {
                path: "lines/detail/:slug",
                name: "LinesDetail",
                component: () => import("../pages/app/lines/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Detalles de línea"
                }
            },
            {
                path: "lines/clone/:slugClone",
                name: "LinesClone",
                component: () => import("../pages/app/lines/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Clonar línea"
                }
            },

            {
                path: "logistic-configurations",
                name: "SupplyChainsIndex",
                component: () =>
                    import("../pages/app/logistic-configurations/index.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Configuraciones logísticas"
                }
            },
            {
                path: "logistic-configurations/new",
                name: "LogisticConfigurationNew",
                component: () =>
                    import("../pages/app/logistic-configurations/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Nueva configuración logística"
                }
            },
            {
                path: "logistic-configurations/detail/:slug",
                name: "LogisticConfigurationDetail",
                component: () =>
                    import("../pages/app/logistic-configurations/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                props: true,
                meta: {
                    pageTitle: "Detalles de configuracíon logistica"
                }
            },
            {
                path: "logistic-configurations/clone/:idClone",
                name: "LogisticConfigurationsClone",
                component: () =>
                    import("../pages/app/logistic-configurations/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                props: true,
                meta: {
                    pageTitle: "Detalles de configuracíon logistica"
                }
            },

            {
                path: "orders",
                name: "OrdersIndex",
                component: () => import("../pages/app/orders/index.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Ordenes"
                }
            },
            {
                path: "orders/own",
                name: "OrdersOwn",
                component: () => import("../pages/app/orders/own.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Ordenes en comercio"
                }
            },
            {
                path: "orders/references",
                name: "OrdersReferences",
                component: () => import("../pages/app/orders/references.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Más ordenadas"
                }
            },
            {
                path: "orders/schedule",
                name: "OrdersSchedule",
                component: () => import("../pages/app/orders/schedule.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Calendario de entregas"
                }
            },
            {
                path: "orders/refactor/:id",
                name: "OrdersRefactor",
                component: () => import("../pages/app/orders/refactor.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Refactorizar orden"
                }
            },
            {
                path: "orders/new",
                name: "OrderNew",
                component: () => import("../pages/app/orders/new.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Venta"
                }
            },
            {
                path: "orders/record",
                name: "RecordOrder",
                component: () => import("../pages/app/orders/record.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Venta Rápida"
                }
            },
            {
                path: "orders/new-in-merchant",
                name: "OrderNewInMerchant",
                component: () => import("../pages/app/orders/new.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Venta en comercio"
                }
            },
            {
                path: "orders/product",
                name: "SalesProduct",
                component: () => import("../pages/app/orders/product.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Referencias pendientes"
                }
            },
            {
                path: "orders/product/:idSku",
                name: "OrdersProductId",
                component: () => import("../pages/app/orders/product.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Ventas por producto"
                }
            },
            {
                path: "orders/detail/:id",
                name: "OrdersDetail",
                component: () => import("../pages/app/orders/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Detalle de order"
                }
            },
            {
                path: "orders/:merchantType/:merchantId/detail/:id",
                name: "OrdersReducedDetail",
                component: () => import("../pages/app/orders/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Detalle de order"
                }
            },

            {
                path: "orders-groups",
                name: "OrdersGroupsIndex",
                component: () => import("../pages/app/orders-groups/index.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Agrupación de ordenes"
                }
            },
            {
                path: "orders-groups/detail/:id",
                name: "OrdersGroupsDetail",
                component: () =>
                    import("../pages/app/orders-groups/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                props: true,
                meta: {
                    pageTitle: "Detalle agrupación de orden"
                }
            },

            {
                path: "profile",
                name: "Profile",
                beforeEnter: validateRequiresAuthenticated,
                component: () => import("../pages/app/profile.vue"),
                meta: {
                    pageTitle: "Perfil"
                }
            },

            {
                path: "products",
                name: "ProductsIndex",
                component: () => import("../pages/app/products/index.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Listado de productos"
                }
            },
            {
                path: "products/search",
                name: "ProductsSearchPage",
                component: () => import("../pages/app/products/search.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Búsqueda de productos"
                }
            },
            {
                path: "products/new",
                name: "ProductsNew",
                component: () => import("../pages/app/products/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Crear producto"
                }
            },
            {
                path: "products/highlight",
                name: "ReferencesHighlight",
                component: () => import("../pages/app/products/highlight.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Productos destacados"
                }
            },
            {
                path: "products/massive",
                name: "ProductsLoad",
                component: () => import("../pages/app/products/massive/index"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Carga masiva de productos"
                }
            },
            {
                path: "products/massive/load",
                name: "ProductsMassiveLoad",
                component: () =>
                    import("../pages/app/products/massive/load.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Carga masiva de productos"
                }
            },
            {
                path: "products/massive/load-with-inventories",
                name: "ProductsAndInventoriesMassiveLoad",
                component: () =>
                    import(
                        "../pages/app/products/massive/load-with-inventories.vue"
                    ),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Carga masiva de productos e inventario"
                }
            },
            {
                path: "products/record",
                name: "ProductFastCreate",
                component: () => import("../pages/app/products/record.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Creación rápida"
                }
            },
            {
                path: "products/detail/:id",
                name: "ProductsDetail",
                component: () => import("../pages/app/products/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                props: true,
                meta: {
                    pageTitle: "Detalle de producto"
                }
            },
            {
                path: "products/clone/:idClone",
                name: "ProductsClone",
                component: () => import("../pages/app/products/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Clonar producto"
                }
            },

            {
                path: "roles",
                name: "ListRoles",
                component: () => import("../pages/app/roles/index.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Lista de roles"
                }
            },
            {
                path: "roles/detail/:slug",
                name: "RolesDetail",
                component: () => import("../pages/app/roles/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Detalle de roles"
                }
            },

            {
                path: "merchant/services",
                name: "MerchantServices",
                component: () => import("../pages/app/merchant/services.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Servicios"
                }
            },
            {
                path: "merchant/box",
                name: "MerchantBox",
                component: () => import("../pages/app/merchant/box.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Caja"
                }
            },

            {
                path: "stores",
                name: "StoresIndex",
                component: () => import("../pages/app/stores/index.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Listado de tiendas"
                }
            },
            {
                path: "stores/new",
                name: "StoreNew",
                component: () => import("../pages/app/stores/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Nueva tienda"
                }
            },
            {
                path: "stores/detail/:id",
                name: "StoresDetail",
                component: () => import("../pages/app/stores/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                props: true,
                meta: {
                    pageTitle: "Detalle de tienda"
                }
            },
            {
                path: "stores/clone/:idClone",
                name: "StoresClone",
                component: () => import("../pages/app/stores/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Clonar tienda"
                }
            },
            {
                path: "stores/record",
                name: "StoreCreate",
                component: () => import("../pages/app/stores/reduced.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Registrar tienda"
                }
            },
            {
                path: "stores/reduced/:id",
                name: "StoresReduced",
                component: () => import("../pages/app/stores/reduced.vue"),
                beforeEnter: validateRequiresAuthenticated,
                props: true,
                meta: {
                    pageTitle: "Detalle de tienda"
                }
            },
            {
                path: "sublines",
                name: "SublinesIndex",
                component: () => import("../pages/app/sublines/index.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Listado de subLíneas"
                }
            },
            {
                path: "sublines/new",
                name: "SublinesNew",
                component: () => import("../pages/app/sublines/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Registrar sublínea"
                }
            },
            {
                path: "sublines/detail/:slug",
                name: "SublinesDetail",
                component: () => import("../pages/app/sublines/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                props: true,
                meta: {
                    pageTitle: "Detalles de sublínea"
                }
            },
            {
                path: "sublines/clone/:slugClone",
                name: "SublinesClone",
                component: () => import("../pages/app/sublines/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Clonar sublínea"
                }
            },
            {
                path: "suppliers",
                name: "SuppliersIndex",
                component: () => import("../pages/app/suppliers/index.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Listado de proveedores"
                }
            },
            {
                path: "suppliers/new",
                name: "SupplierNew",
                component: () => import("../pages/app/suppliers/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Nuevo proveedor"
                }
            },
            {
                path: "suppliers/detail/:id",
                name: "SuppliersDetail",
                component: () => import("../pages/app/suppliers/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Detalles de proveedor"
                }
            },
            {
                path: "suppliers/clone/:idClone",
                name: "SuppliersClone",
                component: () => import("../pages/app/suppliers/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Clonar proveedor"
                }
            },

            {
                path: "supply-lists",
                name: "SupplyListIndex",
                component: () => import("../pages/app/supply-lists/index.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Listas de compras"
                }
            },
            {
                path: "supply-lists/new",
                name: "SupplyListNew",
                component: () => import("../pages/app/supply-lists/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Nueva lista de compras"
                }
            },
            {
                path: "supply-lists/detail/:id",
                name: "SupplyListDetail",
                component: () => import("../pages/app/supply-lists/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                props: true,
                meta: {
                    pageTitle: "Detalles de lista de compras"
                }
            },
            {
                path: "supply-lists/clone/:idClone",
                name: "SupplyListClone",
                component: () => import("../pages/app/supply-lists/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                props: true,
                meta: {
                    pageTitle: "Detalles de lista de compras"
                }
            },

            {
                path: "users",
                name: "CollaboratorsIndex",
                component: () => import("../pages/app/users/index.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Usuarios"
                }
            },
            {
                path: "users/detail/:id",
                name: "UsersDetail",
                component: () => import("../pages/app/users/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                props: true,
                meta: {
                    pageTitle: "Detalles de colaborador"
                }
            },

            {
                path: "warehouses",
                name: "WarehousesIndex",
                component: () => import("../pages/app/warehouses/index.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Listado de bodega"
                }
            },
            {
                path: "warehouses/detail/:id",
                name: "WarehouseDetails",
                component: () => import("../pages/app/warehouses/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                props: true,
                meta: {
                    pageTitle: "Detalle de bodega"
                }
            },
            {
                path: "warehouses/new",
                name: "WarehouseNew",
                component: () => import("../pages/app/warehouses/detail.vue"),
                beforeEnter: validateRequiresAuthenticated,
                meta: {
                    pageTitle: "Nueva bodega"
                }
            }
        ]
    },
    {
        path: "/not-found",
        name: "NotFount",
        component: NotFound
    },
    {
        path: "*",
        redirect: "/not-found"
    }
];

const router = new VueRouter({
    mode: "history",
    base: SITE_BASE_PATH,
    routes,
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(_to, _from, _savedPosition) {
        return { top: 0 };
    }
});

router.afterEach((to) => {
    store.commit("control/UPDATED_SIDEBAR_STATE", false);
    Vue.nextTick(() => {
        document.title = to.meta.pageTitle || MARKETPLACE_PORTAL_TITLE;
    });
});

export default router;
